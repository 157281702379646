<template>
    <div class="eCommerce-container">
        <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
            <el-table-column label="商品信息">
                <template slot-scope="scope">
                    <div class="goods-info">
                        <div class="good-img" @click="toGoodDetail(scope.row.goods_id)">
                            <img :src="`${hosturl}${scope.row.goods_img}`" alt="">
                        </div>
                        <div class="info-right text-overflow-2" @click="toGoodDetail(scope.row.goods_id)">{{scope.row.goods_title}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="month_sales" label="30天销量" align="center"></el-table-column>
            <el-table-column prop="cost_price" label="成本价" align="center"></el-table-column>
            <el-table-column prop="sales_price" label="销售价" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="changePrice(scope.row.goods_id)">修改价格</el-link>
                </template>
            </el-table-column>
            <template slot="empty">
                <div class="null-data">
                    <div style="display: flex;flex-direction: column;align-items: center;">
                        <img src="../../../../../assets/images/student/goods-null-data.png" alt="">
                        <span>暂无数据</span>
                    </div>
                </div>
            </template>
        </el-table>

        <el-dialog title="修改价格" :visible.sync="dialogVisible" width="500px" custom-class="custom-dialog"
                   :close-on-click-modal="false" @opened="openedDialog" @close="closeDialog">
            <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="销售价" prop="sales_price">
                    <el-input-number class="number-input" v-model="addForm.sales_price" :precision="2" :controls="false" :min="0" :max="99999999.99" style="width: 100%"></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" style="text-align: center">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitForm('addForm')" style="margin-left: 30px">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { pricing_managementGetStuPricing, pricing_managementStuEaxmPricing, pricing_managementStuPricing } from '@/utils/apis.js'
    export default {
        data() {
            return {
                op_id: Number(this.$route.query.op_id) || null,
                course_id: Number(this.$route.query.course_id) || null,
                infoList: [],
                isExamOrTrain: localStorage.getItem('examId'), // 有考试， 没有训练
                studentId: localStorage.getItem('studentId'),
                hosturl: localStorage.getItem('hosturl'),
                // S 弹窗
                dialogVisible: false,
                addForm: {
                    sales_price: void 0,
                },
                rules: {
                    sales_price: { required: true, message: '请输入价格', trigger: 'blur' },
                },
                // E 弹窗
                currentEditId: null,
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            // 获取列表
            getList() {
                let params = {
                    op_id: this.$route.query.op_id,
                }
                if (this.isExamOrTrain) {
                    params.eaxm_id = this.isExamOrTrain
                    params.type = 2
                } else {
                    params.course_id = this.$route.query.course_id
                    params.type = 1
                }
                pricing_managementGetStuPricing(params).then((res) => {
                    this.infoList = res.data
                }).catch((err) => {})
            },
            // 查看
            changePrice(id) {
                this.dialogVisible = true
                this.currentEditId = id
            },
            // 去商品详情
            toGoodDetail(id) {
                let routeDate = this.$router.resolve({
                    path: '/goods/detail',
                    query:{
                        g_id: id,
                        type: 1
                    }
                })
                window.open(routeDate.href, '_blank')
            },
            // 打开弹窗
            openedDialog() {

            },
            // 关闭弹窗
            closeDialog() {
                this.resetForm()
                this.currentEditId = null
            },
            // 弹窗内保存
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let params = {
                            goods_id: this.currentEditId,
                            sales_price: this.addForm.sales_price,
                            op_id: this.op_id,
                        }
                        if (this.isExamOrTrain) {
                            params.eaxm_id = this.isExamOrTrain
                            params.user_id = this.studentId
                        } else {
                            params.course_id = this.course_id
                        }
                        if (this.isExamOrTrain) {
                            pricing_managementStuEaxmPricing(params).then((res) => {
                                this.$message.success(res.msg)
                                this.getList()
                                this.dialogVisible = false
                                this.resetForm()
                            })
                        } else {
                            pricing_managementStuPricing(params).then((res) => {
                                this.$message.success(res.msg)
                                this.getList()
                                this.dialogVisible = false
                                this.resetForm()
                            })
                        }
                    } else {
                        return false;
                    }
                });
            },
            // 重置表单
            resetForm() {
                this.$refs.addForm.resetFields();
                this.addForm = {
                    sales_price: void 0,
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .eCommerce-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }
    .goods-info {
        display: flex;
        align-items: center;
        .good-img {
            width: 80px;
            height: 80px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                max-width: 100%;
            }
        }
        .info-right {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            cursor: pointer;
        }
    }
</style>